<template>
  <div class="templete-manage">
    <a-spin :spinning="spinning">
      <!-- <react-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :columns="columns"
        :hide-row-selection="true"
        :is-full="true"
        :current-page="pageNumber"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :actions-type="actionsType"
        :is-full="true"
        :current-page="pageNumber"
        :hide-row-selection="true"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row align="middle" :gutter="8">
          <a-col :span="4">
            <a-input
              allowClear
              v-model.trim="params.agreementTemplateName"
              :placeholder="l('请输入协议名称')"
            />
          </a-col>
          <a-col :span="4">
            <a-input
              allowClear
              v-model.trim="params.projectName"
              :placeholder="l('请输入项目名称')"
            />
          </a-col>
          <a-col :span="4">
            <a-select
              style="width: 100%"
              @change="standardChange"
              placeholder="请选择类型"
              v-model="params.isStandard"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in standardOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="4">
            <a-select
              style="width: 100%"
              @change="statusChange"
              placeholder="请选择状态"
              v-model="params.agreementTemplateStatus"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in statusOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col
            :span="4"
            v-if="isGranted('Pages.ProjectAgreementPermissions.Query')"
          >
            <a-button type="primary" @click="search">
              {{ l("Search") }}
            </a-button>
            <a-button @click="clearFilterAndRefresh">{{ l("Reset") }}</a-button>
          </a-col>
          <a-col :span="4" style="text-align: right">
            <a-button
              type="primary"
              v-if="isGranted('Pages.ProjectAgreementPermissions.Create')"
              @click="goAddTemplete('create')"
            >
              添加协议模版
            </a-button>
          </a-col>
        </a-row>
      </ele-table>
    </a-spin>
  </div>
</template>


<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { AppConsts } from "@/abpPro/AppConsts";

export default {
  mixins: [AppComponentBase],
  components: {
    EleTable,
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      params: {
        filterText: "",
        agreementTemplateName: "",
        projectName: "",
        agreementTemplateStatus: undefined,
        isStandard: undefined,
      },
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      actionsType: {},
      columns: [],
      tableData: [],
      standardOption: [
        { label: "全部", value: null },
        { label: "标准版", value: 1 },
        { label: "非标准版", value: 0 },
      ],
      statusOption: [
        { label: "全部", value: null },
        { label: "启用", value: 1 },
        { label: "禁用", value: 0 },
      ],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
    this.initColums();
    this.getData();
  },
  mounted() {},
  methods: {
    standardChange(val) {
      console.log(val);
    },
    statusChange(val) {
      console.log(val);
    },
    initColums() {
      let _this = this;
      this.columns = [
        {
          title: this.l("模板名称"),
          dataIndex: "agreementTemplateName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "agreementTemplateName" },
          ellipsis: true,
        },
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          sorter: false,
          // width: 250,
          align: "center",
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: this.l("开始时间"),
          dataIndex: "beginDateDesc",
          sorter: false,
          // width: 120,
          align: "center",
          scopedSlots: { customRender: "beginDate" },
        },
        {
          title: this.l("结束时间"),
          dataIndex: "endDateDesc",
          sorter: false,
          // width: 120,
          align: "center",
          scopedSlots: { customRender: "endDate" },
        },
        {
          title: this.l("是否标准模板"),
          dataIndex: "isStandardDesc",
          sorter: false,
          // width: 130,
          align: "center",
          scopedSlots: { customRender: "isStandardStr" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          width: 130,
          align: "center",
          // width: "100",
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    search() {
      this.pageNumber = 1
      this.request.skipCount = 0
      this.getData()
    },
    async getData() {
      this.spinning = true;
      const opts = {
        ...this.params,
        isStandard:
          this.params.isStandard === 1
            ? true
            : this.params.isStandard === 0
            ? false
            : "",
        sorting: this.request.sorting,
        maxResultCount: this.request.maxResultCount,
        skipCount: this.request.skipCount,
      };
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/ProjectAgreement/GetPaged",
          params: opts,
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            beginDateDesc: item.beginDate
              ? moment(item.beginDate).format("YYYY-MM-DD")
              : "-",
            endDateDesc: item.endDate
              ? moment(item.endDate).format("YYYY-MM-DD")
              : "-",
            isStandardDesc: item.isStandard ? "是" : "否",
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.params = {
        filterText: "",
        agreementTemplateName: "",
        projectName: "",
        agreementTemplateStatus: undefined,
        isStandard: undefined,
      };
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted(
                "Pages.ProjectAgreementPermissions.Preview"
              ),
              name: this.l("预览"),
              icon: "edit",
              fn: (data) => {
                _this.previewItem(data);
              },
            },
          ],
          // delete: {
          //   granted: true,
          //   name: this.l("禁用"),
          //   fn: (data) => {
          //     // _this.deleteItem(data.id)
          //   },
          // },
        },
      };
      this.actionsType = obj;
    },
    goAddTemplete(id) {
      this.$router.push({
        path: `/app/fs/nAddTemplete/${id}`,
      });
    },
    goSignMethod(id) {
      this.$router.push({
        path: `/app/fs/unSignMethod/d5da7338-95fb-4217-98e8-08d8eddc785b/0`,
      });
    },
    previewItem(item) {
      const { pdfTemplateUrl } = item;
      window.open(`${AppConsts.uploadBaseUrl}${pdfTemplateUrl}`);
    },
  },
};
</script>

<style scoped lang="less">
.templete-manage {
  // min-height: 600px;
}
</style>